import Aos from "aos"
import { graphql } from "gatsby"
import React from "react"
import BackToTop from "../../components/BackToTop"
import Layout from "../../components/Layout/index"
import LuxuryRoom from "../../components/luxuryRoom"
import Seo from "../../components/Seo"
import { PortableText } from "@portabletext/react"
const StateParks = ({ data }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  const {
    title: pageTitle,
    stateParksPage: { title, _rawDescription, parks },
  } = data?.sanityPages
  return (
    <>
      <Seo
        title={pageTitle}
        description={
          "Noyo harbor team guide for vailable nearest state parks. "
        }
      />
      <Layout>
        <BackToTop />
        <div className="environment__area state__park">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="state_blk"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <h3>{title}</h3>
                  <PortableText value={_rawDescription} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-5">
                {parks.map((item, index) => (
                  <LuxuryRoom
                    key={index}
                    data={item}
                    boxClass={"luxury__item"}
                    index={index}
                    length={parks.length}
                    btnNotrequired
                    stateParkImage
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default StateParks

export const stateParkContent = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      stateParksPage {
        title
        _rawDescription
        parks {
          title
          description
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
        }
      }
    }
  }
`
